<template>
  <v-row align="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ edit ? "Edit" : "Add" }} Faq</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form lazy-validation autocomplete="off">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="faq.title"
                    label="Title"
                    outlined
                    dense
                    required
                    hide-details
                  ></v-text-field>
                  <span class="text-danger" v-if="$v.faq.title.$error">
                    Title is Required !!
                  </span>
                  <span class="text-danger" v-if="this.errors.title">
                    {{ this.errors.title[0] }}
                  </span>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select
                    v-model="faq.faq_category_id"
                    :items="faqCategories"
                    item-text="title"
                    item-value="id"
                    label="FAQ Category"
                    outlined
                    hide-details
                    dense
                  ></v-select>
                  <span
                    class="text-danger"
                    v-if="$v.faq.faq_category_id.$error"
                  >
                    Faq Category is Required !!
                  </span>
                </v-col>

                <v-col cols="12">
                  <label>Description</label>
                  <ckeditor
                    v-model="faq.description"
                    :config="editorConfig"
                  ></ckeditor>
                  <span class="text-danger" v-if="$v.faq.description.$error">
                    Description is Required !!
                  </span>
                  <span class="text-danger" v-if="this.errors.description">
                    {{ this.errors.description[0] }}
                  </span>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="faq.is_active"
                    label="Status"
                    outlined
                    dense
                    required
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="createOrUpdate">
            {{ edit ? "Update" : "Save" }}
          </v-btn>
          <v-btn color="red darken-1" dark @click="close"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import FaqService from "@/service/cms/faq/FaqService";
import FaqCategoryService from "@/service/cms/faq/category/FaqCategoryService";

const faqService = new FaqService();
const faqCategoryService = new FaqCategoryService();
export default {
  name: "CreateAndUpdate",
  data() {
    return {
      dialog: false,
      edit: false,
      faqCategories: [],
      errors: [],
      faq: {
        title: "",
        description: "",
        faq_category_id: "",
        is_active: true
      },
      editorConfig: {
        toolbar: [["Bold", "Italic", "Underline", "BulletedList"]]
      }
    };
  },
  mounted() {
    this.getAllFaqCategories();
  },
  validations: {
    faq: {
      title: { required },
      description: { required },
      faq_category_id: { required }
    }
  },
  methods: {
    getAllFaqCategories() {
      faqCategoryService
        .all()
        .then(response => {
          this.faqCategories = response.data.data;
        })
        .finally(() => {
          return false;
        });
    },

    close() {
      this.dialog = false;
      this.reset();
    },

    createFaq() {
      this.dialog = true;
      this.edit = false;
      this.reset();
    },

    editFaq(faq) {
      this.edit = true;
      this.dialog = true;
      this.reset();
      this.faq = faq;
    },

    createOrUpdate() {
      this.$v.faq.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (!this.edit) {
          this.create();
        } else {
          this.update();
        }
      }
    },
    reset() {
      this.edit = false;
      this.$v.$reset();
      this.faq = {
        title: "",
        description: "",
        is_active: true
      };
    },
    create() {
      faqService
        .store(this.faq)
        .then(response => {
          if (response.data.status == "OK") {
            this.$snotify.success("Add faq Successfully");
            this.$emit("refresh");
            this.close();
          } else {
            this.$snotify.error("Something Went Wrong");
          }
        })
        .catch(err => {
          if (err.response.status == 422) {
            this.errors = err.response.data.errors;
          }
          setTimeout(() => {
            this.errors = [];
          }, 3000);
        });
    },
    update() {
      faqService.update(this.faq.id, this.faq).then(response => {
        if (response.data.status == "OK") {
          this.$snotify.success("Add faq Successfully");
          this.$emit("refresh");
          this.close();
        } else {
          this.$snotify.error("Something Went Wrong");
        }
      });
    }
  }
};
</script>

<style scoped></style>
